import {ReactNode, useEffect, useState} from 'react';

import SessionContext, {SessionContextProps} from '@src/contexts/session';

export default function SessionProvider({children}: {children: ReactNode}) {
  const [context, setContext] = useState<SessionContextProps>({
    isLoading: true,
  });

  useEffect(() => {
    const abortController = new AbortController();

    const main = async () => {
      const response = await fetch('/api/workos/me', {
        method: 'GET',
        credentials: 'include',
        signal: abortController.signal,
      });

      if (!response.ok) {
        setContext({
          isLoading: false,
        });

        return;
      }

      const body = await response.json();

      if (!body.isAuthenticated) {
        setContext({
          isLoading: false,
        });

        return;
      }

      setContext({
        isLoading: false,
        user: body.user,
        organization: body.organization,
      });
    };

    void main();

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <SessionContext.Provider value={context}>{children}</SessionContext.Provider>
  );
}
