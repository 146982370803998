'use client';

import {ReactNode, useEffect} from 'react';

import {useMediaQuery} from '@mui/material';
import {AppRouterCacheProvider} from '@mui/material-nextjs/v14-appRouter';
import CssBaseline from '@mui/material/CssBaseline';
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {usePathname} from 'next/navigation';
import {PostHogProvider} from 'posthog-js/react';
import TagManager from 'react-gtm-module';
import toast from 'react-hot-toast';
import {SWRConfig, SWRConfiguration} from 'swr';

import AuthSessionProvider from '@components/providers/auth';
import IntercomProvider from '@components/providers/intercom';
import SmallScreen from '@components/small-screen';
import {AuthenticationGuard} from '@src/components/authentication-guard';
import CssVariables from '@src/components/CssVariables';
import Alert from '@src/components/legacy/Alert';
import PostHog from '@src/components/posthog';
import LiveblocksProvider from '@src/components/providers/liveblocks';
import TetraBotProvider from '@src/components/providers/tetra-bot';
import UppyProvider from '@src/components/providers/uppy';
import theme from '@src/library/theme';

const swrConfig: SWRConfiguration = {
  onError(error) {
    const isForbidden =
      error.response?.errors?.[0]?.extensions?.code === 'FORBIDDEN';

    if (isForbidden) {
      const toastInstance = toast.custom((notification) => {
        return (
          <Alert
            classnames={
              notification.visible
                ? 'toastNotificationOpen'
                : 'toastNotificationClosed'
            }
            severity="error"
            onClose={() => {
              toast.dismiss(toastInstance);
            }}
          >
            You don&apos;t have permission to perform this action.
          </Alert>
        );
      });
    }
  },
};

export default function Providers({children}: {children: ReactNode}) {
  const pathname = usePathname();
  const smallDevice = useMediaQuery('only screen and (max-width: 500px)');
  const isLiveNotes = pathname.includes('live-notes');

  // Initialize GTM
  useEffect(() => {
    if (process.env.GTM_ID) {
      const tagManagerArgs = {
        gtmId: process.env.GTM_ID,
        dataLayer: {},
      };

      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  if (smallDevice && !isLiveNotes) {
    return <SmallScreen />;
  }

  return (
    <AppRouterCacheProvider>
      <PostHogProvider
        apiKey={process.env.NEXT_PUBLIC_POSTHOG_KEY}
        options={{
          autocapture: false,
          disable_session_recording: process.env.NODE_ENV === 'development',
          opt_in_site_apps: true,
          session_recording: {
            maskTextSelector: '.ProseMirror',
          },
        }}
      >
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <CssVariables />
            <AuthSessionProvider>
              <AuthenticationGuard>
                <PostHog>
                  <IntercomProvider>
                    <LiveblocksProvider>
                      <UppyProvider>
                        <TetraBotProvider>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <SWRConfig value={swrConfig}>{children}</SWRConfig>
                          </LocalizationProvider>
                        </TetraBotProvider>
                      </UppyProvider>
                    </LiveblocksProvider>
                  </IntercomProvider>
                </PostHog>
              </AuthenticationGuard>
            </AuthSessionProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </PostHogProvider>
    </AppRouterCacheProvider>
  );
}
