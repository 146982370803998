import {FC, ReactNode} from 'react';

import {LiveblocksProvider, RoomProvider} from '@liveblocks/react';

import {NotificationToast} from '@src/components/alerts/notifications';
import {useCMSClient} from '@src/hooks/useCMSClient';
import useIsPublicRoute from '@src/hooks/useIsPublicRoute';
import {useSubdomain} from '@src/hooks/useSubdomain';
import useUser from '@src/hooks/useUser';

import {
  Presence,
  RoomEvent,
  Storage,
  ThreadMeta,
  UserMeta,
} from '@tetra-next/liveblocks-types';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Liveblocks {
    Presence: Presence;
    Storage: Storage;
    UserMeta: UserMeta;
    RoomEvent: RoomEvent;
    ThreadMetadata: ThreadMeta;
  }
}

interface LiveblocksProviderProps {
  children: ReactNode;
}

const LiveblocksRealtimeProvider: FC<LiveblocksProviderProps> = ({children}) => {
  const {user} = useUser();
  const subdomain = useSubdomain();
  const organization = user?.org_id;
  const isPublicRoute = useIsPublicRoute();
  const CMSClient = useCMSClient();

  return organization && !isPublicRoute ? (
    <LiveblocksProvider
      authEndpoint={async (room = `${subdomain}:${organization}`) => {
        const url = new URL('/liveblocks/auth', process.env.CMS_BASE_URL);

        if (subdomain) {
          url.host = url.host.replace(new RegExp('^cms.'), `cms.${subdomain}.`);
        }

        const response = await fetch(url, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({room}),
        });

        return await response.json();
      }}
      resolveMentionSuggestions={async ({text}) => {
        const {organizationMembers} = await CMSClient.getOrganizationMembers({
          filters: {
            name: {
              containsi: text,
            },
            isTetraGuest: {
              eq: false,
            },
          },
        });

        const organizationMemberUids = organizationMembers.data
          .map((member) => {
            return member.attributes.uid;
          })
          .filter(Boolean);
        return organizationMemberUids;
      }}
      resolveRoomsInfo={async ({roomIds}) => {
        const {currentOrganization} = await CMSClient.getCurrentOrganization();

        return roomIds.map(() => {
          return {name: currentOrganization?.name};
        });
      }}
      resolveUsers={async ({userIds}) => {
        const {organizationMembers} = await CMSClient.getOrganizationMembers({
          filters: {
            uid: {
              in: userIds,
            },
          },
        });

        const map = new Map<string, {name: string; avatar: string}>();
        for (const member of organizationMembers.data) {
          if (!map.has(member.attributes.uid)) {
            map.set(member.attributes.uid, {
              name: member.attributes.name,
              avatar: member.attributes.avatar,
            });
          }
        }

        return userIds.map((userId) => {
          return map.get(userId);
        });
      }}
    >
      <RoomProvider id={`${subdomain}:${organization}`} initialPresence={{}}>
        <NotificationToast />
        {children}
      </RoomProvider>
    </LiveblocksProvider>
  ) : (
    <>{children}</>
  );
};

export default LiveblocksRealtimeProvider;
