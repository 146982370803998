import {AlertColor} from '@mui/material/Alert/Alert';
import {usePostHog} from 'posthog-js/react';
import toast, {Toaster} from 'react-hot-toast';

import Alert from '../legacy/Alert';
import Link from '@components/core/navigation/Link';
import useOrganizationEventListener from '@src/hooks/useOrganizationEventListener';
import useUser from '@src/hooks/useUser';

export const NotificationToast = () => {
  const {user} = useUser();
  const posthog = usePostHog();

  useOrganizationEventListener(({event}) => {
    if (
      event.type === 'recording-transcribing' &&
      event.data.userEmail === user.email
    ) {
      const toastInstance = toast.custom(
        (notification) => {
          return (
            <Alert
              alertTitle={'Transcribing in progress'}
              classnames={
                notification.visible
                  ? 'toastNotificationOpen'
                  : 'toastNotificationClosed'
              }
              severity="info"
              onClose={() => {
                toast.dismiss(toastInstance);
              }}
            >
              We will notify you when it is completed.
            </Alert>
          );
        },
        {
          duration: 10_000,
        }
      );
    }

    if (
      event.type === 'recording-transcribed' &&
      event.data.userEmail === user.email
    ) {
      const {assetTitle, assetUid, projectUid} = event.data;
      const toastInstance = toast.custom(
        (notification) => {
          return (
            <Alert
              classnames={
                notification.visible
                  ? 'toastNotificationOpen'
                  : 'toastNotificationClosed'
              }
              severity="success"
              onClose={() => {
                toast.dismiss(toastInstance);
              }}
            >
              Recording{' '}
              <Link
                href={`/project/${projectUid}/asset/${assetUid}`}
                onClick={() => {
                  toast.dismiss(toastInstance);
                }}
              >
                {assetTitle}
              </Link>{' '}
              transcribed.
            </Alert>
          );
        },
        {
          duration: 10_000,
        }
      );
    }

    if (
      event.type === 'highlight-reel-succeeded' &&
      event.data.userEmail === user.email
    ) {
      const toastInstance = toast.custom(
        (notification) => {
          return (
            <Alert
              alertTitle="Video Reel Created"
              classnames={
                notification.visible
                  ? 'toastNotificationOpen'
                  : 'toastNotificationClosed'
              }
              severity="success"
              onClose={() => {
                toast.dismiss(toastInstance);
              }}
            >
              <a href={event.data.signedUrl} rel="noreferrer" target="_blank">
                Click to view and download
              </a>
            </Alert>
          );
        },
        {
          duration: Number.POSITIVE_INFINITY,
        }
      );
    }

    if (
      event.type === 'highlight-reel-failed' &&
      event.data.userEmail === user.email
    ) {
      const toastInstance = toast.custom(
        (notification) => {
          return (
            <Alert
              alertTitle="Something went wrong"
              classnames={
                notification.visible
                  ? 'toastNotificationOpen'
                  : 'toastNotificationClosed'
              }
              severity="error"
              onClose={() => {
                toast.dismiss(toastInstance);
              }}
            >
              We could not create your reel. Please try again or contact us at
              support@tetra.team
            </Alert>
          );
        },
        {
          duration: Number.POSITIVE_INFINITY,
        }
      );
    }

    if (event.type === 'ai-snippets' && event.data.email === user.email) {
      const {
        aiInProgressNotificationId,
        assetTitle,
        assetUid,
        projectUid,
        questionSnippets,
        tagSnippets,
      } = event.data;
      toast.dismiss(aiInProgressNotificationId);
      if (tagSnippets.result !== 'no tags') {
        const {result, snippetCount} = tagSnippets;
        const postHogEvent =
          result === 'success'
            ? `AI snippets from tag definitions completed with ${
                snippetCount > 0 ? `snippets` : 'no snippets'
              }`
            : `AI snippets from tag definitions failed`;
        posthog.capture(postHogEvent);
        const toastId = toast.custom(
          (notification) => {
            return (
              <Alert
                alertTitle={
                  result === 'success'
                    ? 'Automated Tagging completed'
                    : 'Something went wrong'
                }
                classnames={
                  notification.visible
                    ? 'toastNotificationOpen'
                    : 'toastNotificationClosed'
                }
                severity={result as AlertColor}
                onClose={() => {
                  toast.dismiss(notification.id);
                }}
              >
                {result === 'success' ? (
                  <>
                    TetraBot created {snippetCount} snippets in{' '}
                    <Link
                      href={`/project/${projectUid}/asset/${assetUid}`}
                      onClick={() => {
                        return toast.dismiss(toastId);
                      }}
                    >
                      {assetTitle}
                    </Link>
                  </>
                ) : (
                  <>
                    There was an error with TetraBot&lsquo;s automated tagging in{' '}
                    <Link
                      href={`/project/${projectUid}/asset/${assetUid}`}
                      onClick={() => {
                        return toast.dismiss(toastId);
                      }}
                    >
                      {assetTitle}
                    </Link>
                    . If you continue running into issues, please reach out by
                    clicking the support button at the top.
                  </>
                )}
              </Alert>
            );
          },
          {
            duration: Number.POSITIVE_INFINITY,
          }
        );
      }

      if (questionSnippets.result !== 'no questions') {
        const {result, snippetCount} = questionSnippets;
        const postHogEvent =
          result === 'success'
            ? `AI snippets from questions completed with ${
                snippetCount > 0 ? `snippets` : 'no snippets'
              }`
            : `AI snippets from questions failed`;
        posthog.capture(postHogEvent);
        const toastId = toast.custom(
          (notification) => {
            return (
              <Alert
                alertTitle={
                  result === 'success'
                    ? 'Automated Q&A identification complete'
                    : 'Something went wrong'
                }
                classnames={
                  notification.visible
                    ? 'toastNotificationOpen'
                    : 'toastNotificationClosed'
                }
                severity={result as AlertColor}
                onClose={() => {
                  toast.dismiss(notification.id);
                }}
              >
                {result === 'success' ? (
                  <>
                    TetraBot created {snippetCount} snippets in{' '}
                    <Link
                      href={`/project/${projectUid}/asset/${assetUid}`}
                      onClick={() => {
                        return toast.dismiss(toastId);
                      }}
                    >
                      {assetTitle}
                    </Link>
                  </>
                ) : (
                  <>
                    There was an error with TetraBot&lsquo;s automated Q&A
                    identification in{' '}
                    <Link
                      href={`/project/${projectUid}/asset/${assetUid}`}
                      onClick={() => {
                        return toast.dismiss(toastId);
                      }}
                    >
                      {assetTitle}
                    </Link>
                    . If you continue running into issues, please reach out by
                    clicking the support button at the top.
                  </>
                )}
              </Alert>
            );
          },
          {
            duration: Number.POSITIVE_INFINITY,
          }
        );
      }
    }

    if (
      event.type === 'project-ready-for-tetra-review' &&
      event.data.userEmail === user.email
    ) {
      const toastInstance = toast.custom(
        (notification) => {
          return (
            <Alert
              alertTitle={'Your project has been submitted!'}
              classnames={
                notification.visible
                  ? 'toastNotificationOpen'
                  : 'toastNotificationClosed'
              }
              severity="info"
              onClose={() => {
                toast.dismiss(toastInstance);
              }}
            >
              The Tetra team is reviewing your project and will update you shortly.
            </Alert>
          );
        },
        {
          duration: 10_000,
        }
      );
    }
  });

  return <Toaster position="bottom-right" toastOptions={{duration: 5000}} />;
};
