import {ReactNode, useEffect} from 'react';

import {usePathname, useSearchParams} from 'next/navigation';
import {usePostHog} from 'posthog-js/react';

import useUser from '@src/hooks/useUser';

type Props = {
  children: ReactNode;
};

export default function PostHog({children}: Props) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const posthog = usePostHog();

  // Track pageviews
  useEffect(() => {
    if (pathname && posthog) {
      posthog?.capture('$pageview', {auditLog: 'Viewed page'});
    }
  }, [pathname, searchParams, posthog]);

  const {user} = useUser();
  useEffect(() => {
    if (user) {
      posthog?.identify(user.sub, {
        email: user.email,
      });

      posthog?.group('org', user.org_id, {
        organizationId: user.org_id,
        name: user.org_name,
      });

      posthog?.group('app', 'webApp', {webApp: true});
    }
  }, [user]);

  // PostHog doesn't refetch feature flags when they update,
  // so we're doing it ourselves when the page is visible again (SWR style)
  useEffect(() => {
    const visibilityChanged = () => {
      if (document.visibilityState === 'visible') {
        posthog?.reloadFeatureFlags();
      }
    };

    document.addEventListener('visibilitychange', visibilityChanged);

    return () => {
      document.removeEventListener('visibilitychange', visibilityChanged);
    };
  }, [posthog]);

  // Reload feature flags once every minute to avoid stale
  // feature flags if user hasn't switched tabs
  useEffect(() => {
    if (!posthog) {
      return;
    }

    const timer = setInterval(() => {
      if (document.visibilityState === 'visible') {
        posthog.reloadFeatureFlags();
      }
    }, 60_000);

    return () => {
      clearInterval(timer);
    };
  }, [posthog]);

  return <>{children}</>;
}
