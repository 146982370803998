import {IntercomProvider} from 'react-use-intercom';

import useIsPublicRoute from '@src/hooks/useIsPublicRoute';
import useUser from '@src/hooks/useUser';

export default function Intercom({children}) {
  const {user} = useUser();
  const isPublicRoute = useIsPublicRoute();

  if (isPublicRoute) {
    return <>{children}</>;
  }

  return (
    <IntercomProvider
      appId={process.env.INTERCOM_APP_ID}
      autoBoot
      autoBootProps={{
        hideDefaultLauncher: true,
        email: user?.email,
        userId: user?.sub,
        name: user?.name,
        company: {
          companyId: user?.org_id,
          name: user?.org_name as string,
        },
      }}
    >
      {children}
    </IntercomProvider>
  );
}
