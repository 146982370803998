import {FC, ReactNode, useEffect} from 'react';

import useIsPublicRoute from '@src/hooks/useIsPublicRoute';
import useUser from '@src/hooks/useUser';

import PermissionsProvider from './providers/permissions';

interface Props {
  /**
   * Elements
   */
  children: ReactNode | ReactNode[];
}

export const AuthenticationGuard: FC<Props> = ({children}) => {
  const {isLoading, user} = useUser();

  const isPublicRoute = useIsPublicRoute();

  useEffect(() => {
    if (!isLoading && !user && !isPublicRoute) {
      window.location.href = `/api/workos/login?returnTo=${window.location.href}`;
    }
  }, [isLoading, user, isPublicRoute]);

  if (isPublicRoute) {
    return <>{children}</>;
  }

  if (user && user.org_id) {
    return <PermissionsProvider>{children}</PermissionsProvider>;
  }

  return null;
};
